import axios from 'axios';

const axiosClient = axios.create({
	baseURL: process.env.REACT_APP_API_DOMAIN_URL,
	headers: {
		'x-company-id': process.env.REACT_APP_COMPANY_ID,
	},
});

export default axiosClient;
