import React, { useRef } from 'react';
import whatsapp from '../images/Chat Button.png';
import Draggable from 'react-draggable';

const Whatsapp = () => {
	const dragStartPositionXYRef = useRef();

	return (
		<div
			style={{
				zIndex: 1000,
				position: 'fixed',
				width: 'calc(100% - 40px)',
				maxWidth: 'calc(430px - 40px)',
				height: 'calc(100% - 40px)',
				maxHeight: 'calc(100vh - 40px)',
				pointerEvents: 'none',
				left: '55%',
				transform: 'translate(-50%, 0)',
			}}
		>
			<Draggable
				bounds="parent"
				onStart={(_, data) => {
					dragStartPositionXYRef.current = { x: data.x, y: data.y };
				}}
				onStop={(_, data) => {
					const THRESHOLD = 2;
					const { x, y } = dragStartPositionXYRef.current ?? { x: 0, y: 0 };
					const wasDragged =
						Math.abs(data.x - x) > THRESHOLD &&
						Math.abs(data.y - y) > THRESHOLD;
					if (!wasDragged) {
						window.open('https://wa.me/+601156325382', '_blank');
					}
				}}
			>
				<div
					style={{
						top: '40%',
						right: '5%',
						position: 'fixed',
						pointerEvents: 'auto',
					}}
				>
					<img
						src={whatsapp}
						alt="whatsapp"
						style={{ objectFit: 'contain', width: '80px' }}
					/>
				</div>
			</Draggable>
		</div>
	);
};

export default Whatsapp;
