import { Modal, Button } from 'antd';
import successImage from '../images/checkmark.png';

const SuccessModal = ({ isSuccess, setIsSuccess }) => {
	return (
		<Modal
			open={isSuccess}
			centered
			header={null}
			footer={null}
			// onCancel={() => setIsSuccess(false)}
			closable={false}
			maskClosable={false}
			width={300}
		>
			<div className="text-center font-family" style={{ lineHeight: '20px' }}>
				<img style={{ width: '30%' }} src={successImage} alt="" />
				<h2 style={{ fontWeight: '600', fontSize: '24px' }}>Successful!</h2>
				<span
					className="text-center"
					style={{ fontWeight: '420', fontSize: '16px' }}
				>
					You have successfully submitted your receipt, please allow 3 working
					days of validation.
				</span>
				<br />
				<Button
					style={{
						backgroundColor: '#02BC7D',
						color: 'white',
						width: '70%',
						marginTop: '5%',
						border: 'none',
						fontWeight: 'bold',
						borderRadius: '10px',
					}}
					onClick={() => window.location.reload()}
					className="font-family"
				>
					DONE
				</Button>
			</div>
		</Modal>
	);
};

export default SuccessModal;
