import React, { useEffect, useRef, useState } from 'react';
import topBackgroundImage from '../images/Group 22773.png';
import instruction from '../images/Frame 2608526.png';
import { Form, Input, Button, Checkbox, Select, Modal } from 'antd';
import receiptImg from '../images/Group 22478.png';
import deleteBtn from '../images/deleteBtn.png';
import SuccessModal from '../components/successModal';
import axiosClient from '../utilities/axiosClient';
import tnc from '../images/Abbott Buy More Save More TC_V2_21032024.pdf';
import confirmationImg from '../images/Layer_4.png';

const LandingPage = () => {
	const [form] = Form.useForm();
	const [isSuccess, setIsSuccess] = useState(false);
	const [errorName, setErrorName] = useState(' ');
	const [errorNumber, setErrorNumber] = useState(' ');
	const [name, setName] = useState('');
	const [number, setNumber] = useState('');
	const uploadref = useRef();
	const [imageFile, setImageFile] = useState(null);
	const [imageUrl, setImageUrl] = useState(null);
	const [, setCheckImage] = useState(false);
	const [, setSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [age, setAge] = useState('');
	const [isChecked, setIsChecked] = useState(false);
	const [region, setRegion] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [confirm, setConfirm] = useState(false);
	const [boostId, setBoostId] = useState();
	const [boostNumber, setBoostNumber] = useState();
	useEffect(() => {}, [imageFile, imageUrl]);

	const handleCheckboxChange = (e) => {
		setIsChecked(e.target.checked);
	};

	const handleRegionChange = (value) => {
		setRegion(value);
	};

	const handleDisplayPopUp = () => {
		if (!name) {
			setErrorMessage('Please fill up your name!');
		} else if (!number || errorNumber) {
			setErrorMessage('Please enter valid phone number!');
		} else if (!region) {
			setErrorMessage('Please select a region!');
		} else if (region === 'Sabah' && (!boostId || !boostNumber)) {
			setErrorMessage('Please fill up your boost info!');
		} else if (!imageFile || !imageUrl) {
			setErrorMessage('Please upload your receipt image');
		} else if (!isChecked) {
			setErrorMessage('Please agree to T&C and Policy');
		} else {
			setConfirm(true);
		}
		setTimeout(() => {
			setErrorMessage('');
		}, 1500);
	};

	const isName = (e) => {
		var regex = /^[a-zA-Z ]+( [a-zA-Z ]+)*$/;
		setName(e.target.value);
		if (!regex.test(e.target.value)) {
			setErrorName('Invalid name format');
		} else {
			setErrorName('');
		}
		if (!e.target.value) {
			setErrorName('');
		}
	};

	const isPhone = (e) => {
		setNumber(e.target.value);
		// eslint-disable-next-line no-useless-escape
		let regexPhoneNum = /^(01[0-9]{8,9})$/;
		if (!regexPhoneNum.test(e.target.value)) {
			setErrorNumber('Invalid number format');
		} else {
			setErrorNumber('');
		}
		if (!e.target.value) {
			setErrorNumber('');
		}
	};

	const isAge = (e) => {
		setAge(e.target.value);
	};

	const isBoostID = (e) => {
		setBoostId(e.target.value);
	};

	const isBoostNumber = (e) => {
		setBoostNumber(e.target.value);
	};

	const handleDisplayImage = (e) => {
		let render = new FileReader();
		const supportedImgFormat = ['image/jpeg', 'image/jpg', 'image/png'];
		errorMessage && setErrorMessage(null);
		if (e.target.files[0]) {
			const selectedFile = e.target.files[0];
			if (selectedFile.size <= 10 * 1024 * 1024) {
				if (supportedImgFormat.includes(selectedFile.type)) {
					setCheckImage(false);
					setImageFile(selectedFile);
					render.readAsDataURL(selectedFile);
					render.onload = (res) => {
						setImageUrl(res.target.result);
					};
				} else {
					setErrorMessage('Please upload a JPEG, JPG, or PNG image only!');
					setTimeout(() => {
						setErrorMessage('');
					}, 1000);
				}
			} else {
				setErrorMessage(
					'File size exceeds 10MB. Please upload a smaller image.',
				);
				setTimeout(() => {
					setErrorMessage('');
				}, 2000);
			}
		}
	};

	const handleConfirmSubmit = async () => {
		setConfirm(false);
		setIsSubmitting(true);

		if (isSubmitting) return;
		const { name, region, phone } = form.getFieldsValue();
		try {
			setIsSubmitting(true);
			const checkRegion =
				region === 'West Malaysia'
					? process.env.REACT_APP_WEST_MSIA_CAMPAIGN_ID
					: region === 'Sabah'
						? process.env.REACT_APP_SABAH_CAMPAIGN_ID
						: region === 'Sarawak'
							? process.env.REACT_APP_SARAWAK_CAMPAIGN_ID
							: null;

			if (!checkRegion) {
				setErrorMessage('Please select a region!');
				setIsSubmitting(false);
				return;
			}

			const extendedFields = { age: age, region: region };
			const campaignInput = {
				BOOST: {
					userId: boostId,
					phone: '6' + boostNumber,
				},
			};
			const formData = new FormData();
			formData.append('extendedFields', JSON.stringify(extendedFields));
			formData.append('name', name);
			formData.append('phone', '6' + phone);
			formData.append('image', imageFile);
			region === 'Sabah' &&
				formData.append('campaignInput', JSON.stringify(campaignInput));

			const res = await axiosClient.post(
				`/public/campaign/${checkRegion}/upload-receipt`,
				formData,
			);
			if (res && res.data && res.data.data) {
				setIsSuccess(true);
			} else {
				setErrorMessage('Invalid error occurred');
			}
		} catch (error) {
			console.error('Error occurred:', error);
			setErrorMessage(
				error?.response?.data?.message ||
					'An error occurred while submitting the form',
			);
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleCancelSubmit = () => {
		setConfirm(false);
		setIsSubmitting(false);
	};

	return (
		<>
			<div
				style={{
					width: '100%',
					minHeight: '100vh',
					backgroundSize: '100%',
					backgroundRepeat: 'none',
					backgroundPosition: 'top center',
					overflow: 'hidden',
					position: 'relative',
					flexDirection: 'column',
					alignItems: 'center',
				}}
				className="primary-color"
			>
				<img
					src={topBackgroundImage}
					alt=""
					style={{ width: '100%', position: 'absolute', display: 'flex' }}
				/>
				<img
					src={instruction}
					alt=""
					style={{
						width: '90%',
						height: '100%',
						objectFit: 'contain',
						position: 'relative',
						marginTop: '80%',
					}}
				/>

				<div style={{ marginLeft: '8%', marginRight: '8%' }}>
					<p style={{ fontSize: '26px', fontWeight: '550' }}>
						Kindly fill in the following
						<br /> info and upload your receipt!
					</p>
					<p style={{ fontSize: '20px', fontWeight: '550' }}>
						Step 1 : Fill up the details
					</p>
					<Form
						layout="vertical"
						name="register"
						form={form}
						// onFinish={onFinish}
						autoComplete="off"
						className="font-family"
					>
						<Form.Item
							name="name"
							label={
								<label
									className="primary-color font-size-16 font-family"
									style={{ fontWeight: '450' }}
								>
									Full Name
								</label>
							}
							rules={[
								{
									message: 'Please enter your full name',
								},
							]}
						>
							<Input
								type="text"
								placeholder="John Doe"
								onChange={isName}
								required
								className="font-family"
							/>
						</Form.Item>
						{errorName ? (
							<>
								{' '}
								<div
									style={{
										color: 'red',
										marginTop: '-5%',
										textAlign: 'left',
									}}
								>
									{errorName}
								</div>
								<br />
							</>
						) : null}

						<Form.Item
							name="phone"
							label={
								<label
									className="primary-color font-family font-size-16"
									style={{ fontWeight: '450' }}
								>
									Phone Number
								</label>
							}
							rules={[
								{
									message: 'Please enter your phone number!',
								},
							]}
							style={{ color: 'red' }}
						>
							<Input
								type="text"
								placeholder="01xxxxxxxx"
								onChange={isPhone}
								required
								className="font-family"
							/>
						</Form.Item>
						{errorNumber ? (
							<>
								{' '}
								<div
									style={{
										color: 'red',
										marginTop: '-5%',
										textAlign: 'left',
									}}
								>
									{errorNumber}
								</div>
								<br />
							</>
						) : null}
						<Form.Item
							name="age"
							label={
								<label
									className="primary-color font-family font-size-16"
									style={{ fontWeight: '450' }}
								>
									Age (Optional)
								</label>
							}
						>
							<Input
								type="number"
								onChange={isAge}
								className="font-family"
							/>
						</Form.Item>

						<Form.Item
							label={
								<label
									className="primary-color font-family font-size-16"
									style={{ fontWeight: '450' }}
								>
									Region
								</label>
							}
							name="region"
							rules={[
								{
									message: 'Please select the region!',
								},
							]}
						>
							<Select
								placeholder="Select a region"
								value={region}
								onChange={handleRegionChange}
								className="font-family"
							>
								{['West Malaysia', 'Sabah', 'Sarawak'].map((region, index) => (
									<Select.Option
										key={index}
										value={region}
										className="font-family"
									>
										{region}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						{region === 'Sabah' && (
							<>
								<Form.Item
									name="boostUserId"
									label={
										<label
											className="primary-color font-family font-size-16"
											style={{ fontWeight: '450' }}
										>
											[Boost Redemption] Boost User ID
										</label>
									}
									rules={[
										{
											message: 'Invalid User ID',
										},
									]}
									style={{ color: 'red' }}
								>
									<Input
										type="text"
										required
										className="font-family"
										onChange={isBoostID}
									/>
								</Form.Item>

								<Form.Item
									name="boostPhoneNo"
									label={
										<label
											className="primary-color font-family font-size-16"
											style={{ fontWeight: '450' }}
										>
											[Boost Redemption] Registered Boost Mobile No.
										</label>
									}
									rules={[
										{
											pattern: /^(01\d{8,9})$/,
											message: 'Invalid Phone Number',
										},
									]}
									style={{ color: 'red' }}
								>
									<Input
										type="text"
										placeholder="01xxxxxxxx"
										required
										className="font-family"
										onChange={isBoostNumber}
									/>
								</Form.Item>
							</>
						)}
						<div
							className="primary-color font-family"
							style={{ lineHeight: '5px', marginTop: '4em' }}
						>
							<p style={{ fontSize: '20px', fontWeight: '550' }}>
								Step 2 : Upload Receipt
							</p>
							<p
								style={{
									fontSize: '13px',
									fontWeight: '420',
									lineHeight: '1.5em',
								}}
							>
								Upload a copy of your Receipt (up to 10MB):<sup>*</sup>
							</p>
						</div>

						{imageUrl && (
							<div
								style={{
									height: '50px',
									width: '100%',
									background: 'rgba(255, 255, 255, 1)',
									borderRadius: '5px',
									display: 'flex',
									alignItems: 'center',
									marginTop: '5%',
								}}
							>
								<div style={{ width: '80%' }}>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											paddingLeft: '8%',
										}}
									>
										<img
											src={imageUrl}
											alt="receipt"
											style={{
												maxHeight: '50px',
												maxWidth: '45px',
												objectFit: 'contain',
											}}
										/>

										<span style={{ fontSize: '12px', paddingLeft: '3%' }}>
											{imageFile?.name}
										</span>
									</div>
								</div>
								<div style={{ width: '20%' }}>
									<img
										src={deleteBtn}
										alt="delete_btn"
										style={{
											width: '20%',
											cursor: 'pointer',
											objectFit: 'contain',
											alignItems: 'center',
											height: '100%',
										}}
										onClick={() => {
											setImageFile(null);
											setImageUrl(null);
										}}
									/>
								</div>
							</div>
						)}

						<Button
							style={{
								display: 'flex',
								justifyContent: 'center',
								borderRadius: '13px',
								height: '50px',
								width: '100%',
								boxShadow: 'rgba(0, 0, 0, 0.25)',
								border: 'none',
								marginTop: '7%',
							}}
							onClick={() => uploadref.current.click()}
						>
							<img
								src={receiptImg}
								alt=""
								style={{
									width: '50%',
									height: '100%',
									objectFit: 'contain',
								}}
							/>
						</Button>
						<div>
							<input
								type="file"
								name="receiptImage"
								accept="image/jpeg, image/jpg, image/png"
								ref={uploadref}
								onChange={(e) => handleDisplayImage(e)}
								style={{ width: '0px', height: '0px', opacity: '0' }}
							/>
						</div>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								paddingTop: '3%',
								lineHeight: '1.5em',
							}}
						>
							<Checkbox
								onChange={handleCheckboxChange}
								checked={isChecked}
								required
							>
								<p
									style={{
										fontSize: '12px',
										fontWeight: '400',
										maxWidth: '100%',
									}}
									className="primary-color font-family"
								>
									I have read and agree to the{' '}
									<a
										target="_blank"
										rel="noreferrer noopener"
										href={tnc}
										style={{ textDecoration: 'underline', fontWeight: 'bold' }}
										className="primary-color"
									>
										Terms and Conditions
									</a>{' '}
									and{' '}
									<a
										target="_blank"
										rel="noreferrer noopener"
										href="https://www.my.abbott/privacy-policy.html"
										style={{ textDecoration: 'underline', fontWeight: 'bold' }}
										className="primary-color"
									>
										Privacy Policy
									</a>
								</p>
							</Checkbox>
						</div>
						<Form.Item style={{ marginTop: '8%', paddingBottom: '20%' }}>
							<p
								style={{
									color: errorMessage
										? 'red'
										: isSubmitting
											? 'green'
											: 'black',
									textAlign: 'center',
									fontSize: '12px',
								}}
							>
								{errorMessage
									? errorMessage
									: isSubmitting
										? 'Please wait...'
										: null}
							</p>
							<Button
								// loading={loading}
								// htmlType="submit"
								htmlType="button"
								// onClick={() => setConfirm(true)}
								onClick={handleDisplayPopUp}
								style={{
									width: '100%',
									borderRadius: '13px',
									height: '50px',
									fontSize: '16px',
									fontWeight: '600',
									background:
										name &&
										number &&
										imageUrl &&
										imageFile &&
										isChecked &&
										region
											? 'linear-gradient(to right, rgba(245, 233, 170, 1), rgba(212, 174, 81, 1), rgba(245, 233, 170, 1), rgba(251, 244, 182, 1), rgba(251, 244, 182, 1), rgba(251, 244, 182, 1), rgba(212, 174, 81, 1), rgba(245, 233, 170, 1))'
											: '#C6C6C6',
									boxShadow: 'rgba(0, 0, 0, 0.25)',
								}}
								className="primary-color font-family"
								disabled={isSubmitting}
							>
								CONFIRM SUBMISSION
							</Button>
						</Form.Item>
					</Form>
					<SuccessModal isSuccess={isSuccess} setIsSuccess={setIsSuccess} />
					<Modal
						open={confirm}
						centered
						header={null}
						footer={null}
						closable={false}
						maskClosable={false}
						width={300}
					>
						<div
							className="text-center font-family"
							style={{ lineHeight: '20px' }}
						>
							<img
								style={{ width: '55px', objectFit: 'contain' }}
								src={confirmationImg}
								alt=""
							/>
							<h2 style={{ fontWeight: '600', fontSize: '24px' }}>
								Confirmation
							</h2>
							<span
								className="text-center"
								style={{ fontWeight: '420', fontSize: '16px' }}
							>
								Before proceeding, please take a moment to ensure all details
								are correct. Kindly double-check your mobile number:
							</span>
							<br />
							<span style={{ color: 'red' }}>+6{number}</span>
							<br />
							<Button
								style={{
									backgroundColor: '#02BC7D',
									color: 'white',
									width: '70%',
									marginTop: '5%',
									border: 'none',
									fontWeight: 'bold',
									borderRadius: '10px',
								}}
								onClick={handleConfirmSubmit}
								className="font-family"
							>
								SUBMIT
							</Button>
							<Button
								style={{
									backgroundColor: '#A9A9A9',
									color: 'white',
									width: '70%',
									marginTop: '5%',
									border: 'none',
									fontWeight: 'bold',
									borderRadius: '10px',
								}}
								onClick={handleCancelSubmit}
								className="font-family"
							>
								CANCEL
							</Button>
						</div>
					</Modal>
				</div>
			</div>
		</>
	);
};

export default LandingPage;
