import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Whatsapp from './components/whatsapp';
import { useEffect } from 'react';
const packageJson = require('../package.json');

const App = () => {
	const isDev = process.env.REACT_APP_ENV === 'dev';
	const navigate = useNavigate();

	useEffect(() => {
		if (window.location.search !== '/') {
			navigate('/');
		}
	}, [navigate]);

	return (
		<div>
			{isDev && (
				<p
					style={{
						textAlign: 'center',
						color: 'orange',
						background: 'black',
						position: 'fixed',
						left: '50%',
						zIndex: 10000,
						padding: '2px 4px',
						pointerEvents: 'none',
						cursor: 'default',
					}}
				>
					v-{packageJson.version}
				</p>
			)}
			<div className="App">
				<Whatsapp />
				<Routes>
					<Route exact path="/" element={<LandingPage />} />{' '}
				</Routes>
			</div>
		</div>
	);
};

export default App;
